import {
  Button,
  Floater,
  Grid,
  Heading,
  Icon,
  Modal,
  OnlyShow,
  Paper,
  Paragraph,
  showNotification,
} from '@ingeniorforeningen/figurine-core';
import { useRouter } from 'next/router';
import { SyntheticEvent, useEffect, useState } from 'react';

const PreviewControls = () => {
  const { locale } = useRouter();
  const [guid, setGuid] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const insuranceDomains = ['idaforsikring', 'insurance-ui'].join('|');
  const wrongDomainMessage = 'Gå til IDA Forsikring for at kunne bruge linket.';
  const linkNotFoundMessage = 'Linket til Umbraco kunne ikke findes.';

  const editFnString = `
if (typeof window !== "undefined") {
  if (window.location.origin.match("${insuranceDomains}")) {
    const tag = "data-editlink";
    const editLink = document.querySelector(\`meta[\${tag}\`)?.getAttribute(tag);
    if (editLink) {
      window.open(editLink, "_blank");
    } else {
      alert("${linkNotFoundMessage}");
    }
  } else {
    alert("${wrongDomainMessage}");
  }
}
`;

  const editHtml = `<a href='javascript:${editFnString}' style="color: white">Redigér side</a>`;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tag = 'data-guid';
      setGuid(window.document.querySelector(`meta[${tag}]`)?.getAttribute(tag) as string);
    }
  }, []);

  const copyPreviewToClipBoard = (event: SyntheticEvent<Element, Event>) => {
    if (typeof window !== 'undefined') {
      event.preventDefault();
      const previewUrl = `${window?.location?.origin}/api/preview?id=${guid}&locale=${locale}`;
      navigator.clipboard.writeText(previewUrl);
      showNotification({
        text: 'Side er gemt til udklipsholder',
        type: 'success',
      });
    }
  };

  const PreviewActions = (
    <>
      <Heading type="h3" text="Preview" color="light" />
      <Paragraph
        text="Du får vist upubliseret indhold, som ikke er tilgængeligt på sitet. Du kan trække 'Redigér side' knappen op på din bogmærkelinje for altid at kunne gå til siden i Umbraco."
        color="light"
      />
      <Grid container>
        <div
          style={{
            width: '100%',
            height: '3.125rem',
            borderRadius: '2em',
            backgroundColor: '#4a6283',
            color: 'white',
            display: 'flex',
            placeItems: 'center',
            justifyContent: 'center',
          }}
        >
          <a href={`javascript:${editFnString}`} style={{ color: 'white', textDecoration: 'none' }}>
            Redigér side
          </a>
          <Icon name="Edit" ml="xs" color="light" />
        </div>
        <Button
          onClick={copyPreviewToClipBoard}
          color="brandSecondary"
          text="Del side"
          icon="Send"
        />
        <Button
          href={`/api/exit-preview?guid=${guid}`}
          color="brandSecondary"
          text="Forlad preview"
          icon="DoorExit"
        />
      </Grid>
    </>
  );

  return (
    <>
      <OnlyShow largerThan={'lg'}>
        <Floater position={{ left: '75%', right: '5%', bottom: '40%' }}>
          <Paper style={{ width: '18em', display: 'grid', justifyItems: 'center', gap: '1em' }}>
            {PreviewActions}
          </Paper>
        </Floater>
      </OnlyShow>
      <OnlyShow smallerThan={'lg'}>
        <Floater position={{ bottom: '20%', right: '10%' }}>
          <Button text="Preview" icon="Eye" onClick={() => setShowModal(!showModal)} />
          <Modal opened={showModal} onClose={() => setShowModal(false)}>
            {PreviewActions}
          </Modal>
        </Floater>
      </OnlyShow>
    </>
  );
};

export default PreviewControls;
