import { Grid } from '@ingeniorforeningen/figurine-core';
import { pageLayoutStyles } from '../styles';
import { PageTop, RelatedContent } from '@ingeniorforeningen/figurine-cms';
import TableOfContents from '@/components/tableOfContents/TableOfContentsComponent';
import { LayoutProps } from '../types';

export const InfoPageLayout = ({ children, properties, pageType }: LayoutProps) => {
  const hasFloater = Boolean(properties?.showTableOfContents);
  const { classes } = pageLayoutStyles(hasFloater);
  const hasRelatedContent =
    properties?.relatedContentCards && properties?.relatedContentCards?.length > 0;

  return (
    <>
      <PageTop data={{ properties, contentType: pageType }} mt={hasFloater ? '4em' : undefined} />

      <div className={classes.content}>
        <Grid container m={0} p={0}>
          {children}
        </Grid>
        {hasFloater && (
          <div>
            <div style={{ position: 'sticky', top: '5em' }}>
              <TableOfContents items={properties?.components.items[0].areas[0].items} />
            </div>
          </div>
        )}
      </div>

      {hasRelatedContent && (
        <RelatedContent data={properties?.relatedContentCards?.[0]} mt="xl" mb="xl" />
      )}
    </>
  );
};

export default InfoPageLayout;
