import { createStyles } from '@ingeniorforeningen/figurine-core';
import bg from '../../public/assets/images/preview.png';

export const pageLayoutStyles = createStyles((theme, hasFloater: boolean) => ({
  content: {
    maxWidth: '86em', // this matches Figma Specs
    margin: 'auto',
    [theme.fn.largerThan('md')]: {
      display: 'grid',
      gridTemplateColumns: hasFloater ? '1fr 16em' : '1fr',
    },
  },
}));

export const useStyles = createStyles(() => ({
  preview: {
    backgroundImage: `url(${bg.src})`,
    position: 'fixed',
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
}));
