import {
  TableOfContents as FigToC,
  HeaderTableOfContents,
  OnlyShow,
  toId,
} from '@ingeniorforeningen/figurine-core';
import { load } from 'cheerio';
import React from 'react';

type TProps = {
  items: any[];
};

const TableOfContents = ({ items }: TProps) => {
  const headings: { id: string; label: string }[] = [];

  items.forEach((item) => {
    if (item.content.contentType === 'shared_richText') {
      const titles = load(item.content.properties.content.markup)
        .html('h2')
        .split('</h2>')
        .filter((x) => x);
      titles.forEach((title) => {
        const label = title.replace('<h2 dir="ltr">', '').replace('<h2>', '').replace('&nbsp;', '');
        const id = toId(label);
        headings.push({ id, label });
      });
    }
  });

  return headings.length ? (
    <>
      <OnlyShow smallerThan="md">
        <HeaderTableOfContents items={headings} />
      </OnlyShow>
      <OnlyShow largerThan="md">
        <FigToC items={headings} />
      </OnlyShow>
    </>
  ) : null;
};

export default TableOfContents;
