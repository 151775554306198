import { PageTop, RelatedContent } from '@ingeniorforeningen/figurine-cms';
import { LayoutProps } from '../types';

export const SectionFrontPageLayout = ({ children, properties, pageType }: LayoutProps) => {
  const hasRelatedContent =
    properties?.relatedContentCards && properties?.relatedContentCards?.length > 0;
  return (
    <>
      <PageTop data={{ properties, contentType: pageType }} variant="banner" />
      <section>{children}</section>
      {hasRelatedContent && (
        <RelatedContent data={properties?.relatedContentCards?.[0]} mt="xl" mb="xl" />
      )}
    </>
  );
};

export default SectionFrontPageLayout;
